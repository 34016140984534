import React from 'react'
import Styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/styles/section'
import contactBG from '../images/contactBG.jpg'

const Container = Styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  grid-gap: 20px;
  align-items: center;
  width: 100%;
  max-width: 1170px;
  padding: 20px;
  margin: auto;
  background: rgba(255, 255, 255, .6);
  .headerContainer{
    width: 100%;
  }
  @media(max-width: 801px){
    padding-top: 70px;
    .headerContainer{
      width: calc(100% - 40px);
    }
  }
`

const ThankYou = () => (
  <Layout>
    <SEO title="Thank You" />
    <Section bgImage={contactBG}>
      <Container>
        <div className="headerContainer">
          <h2>Thank you for your interest! We will be in contact with you soon to see how we can best serve you.</h2>
        </div>
      </Container>
    </Section>
  </Layout>
)

export default ThankYou
